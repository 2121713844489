.App {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0.05rem;
	/* background: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%); */
}

.title-header {
	font-family: 'Rock Salt', cursive;
	font-size: 3rem;
	margin-block-start: 0;
	margin-block-end: 0;
	user-select: none;
	position: fixed;
	top: 1rem;
}

.message-input {
	display: flex;
	position: fixed;
	top: 20vh;
	resize: none;
	height: 50vh;
	width: 75vw;
	font-size: 1.2rem;
	border: 2px solid #000000;
	border-radius: 0.5rem;
	padding: 10px;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0.05rem;
}

.submit-button {
	display: flex;
	margin: 2vh 0 0 0;
	padding: 20px 40px;
	border: 2px solid #ffffff;
	border-radius: 5px;
	background: #000000;
	color: #ffffff;
	font-size: 1rem;
	cursor: pointer;
	outline: none;
	position: fixed;
	bottom: 10vh;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0.05rem;
}

.message-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0.05rem;
}

.modal {
	position: absolute;
	border: 1px solid #000;
	background-color: #ff8e53;
	padding: 10vh 10vw;
	border-radius: 0.5rem;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0.05rem;
}

.modal-text {
	font-size: 1.2rem;
	padding: 0;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0.05rem;
}

.modal-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 2vw;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0.05rem;
}

.close-button {
	display: flex;
	margin: 10px;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	background: #000000;
	color: #ffffff;
	font-size: 1rem;
	cursor: pointer;
	outline: none;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0.05rem;
}

.intro-button-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0.05rem;
	position: fixed;
	bottom: 14vh;
}

.intro-button {
	margin: 2vh 0 0 0;
	text-align: center;
	width: 60vw;
	height: 8vh;
	border: 2px solid #ffffff;
	border-radius: 5px;
	background: #000000;
	color: #ffffff;
	font-size: 1rem;
	cursor: pointer;
	outline: none;
	white-space: nowrap;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0.05rem;
}

.back-button {
	position: fixed;
	top: 0;
	left: 0;
	font-weight: bold;
	margin: 1vh;
	padding: 1vh 2vw;
	border: 2px solid #ffffff;
	border-radius: 5px;
	background: #000000;
	color: #ffffff;
	font-size: 1rem;
	cursor: pointer;
	outline: none;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0.05rem;
}

.retrieve-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0.05rem;
	position: fixed;
}

.retrieve-input {
	display: flex;
	text-align: center;
	resize: none;
	height: 2vh;
	width: 50vw;
	font-size: 1.2rem;
	border: 2px solid #000000;
	border-radius: 0.5rem;
	padding: 10px;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0.05rem;
}

.retrieve-button {
	display: flex;
	margin: 2vh 0 0 0;
	padding: 20px 40px;
	border: 2px solid #ffffff;
	border-radius: 5px;
	background: #000000;
	color: #ffffff;
	font-size: 1rem;
	cursor: pointer;
	outline: none;
	/* position: fixed; */
	/* bottom: 40vh; */
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0.05rem;
}
.code {
	text-align: center;
	width: 70vw;
}

.message-display {
	position: absolute;
	top: 26vh;
	width: 75vw;
	height: 50vh;
	overflow: auto;
}

.code-display {
	position: fixed;
	bottom: 1vh;
	width: 90vw;
	text-align: center;
	font-size: 1.2rem;
	border: 2px solid #ffffff;
	border-radius: 5px;
	background: #000000;
	color: #ffffff;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0.03rem;
}

.title-definition {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0.05rem;
	position: fixed;
	top: 20vh;
	text-align: center;
}

.title-definition-text {
	width: 80vw;
	font-size: 1.8rem;
}

.italic {
	font-family: 'Libre Baskerville', serif;
}

.share-button {
	position: fixed;
	right: 6vw;
	bottom: 18vw;
	width: 16vw;
	height: 8vw;
	border: 2px solid #ffffff;
	border-radius: 5px;
	background: #000000;
	color: #ffffff;
	margin: 0;
}

@media (max-width: 300px) {
	.code-display {
		font-size: 1rem;
	}
	.share-button {
		right: 8vw;
		bottom: 20vw;
		height: 10vw;
		width: 18vw;
	}
}

@media (max-width: 500px) {
	.title-definition-text {
		font-size: 5vw;
	}
}

@media (min-width: 766px) {
	.title-header {
		font-size: 3rem;
	}

	.message-input {
		font-size: 1.8rem;
	}

	.submit-button {
		font-size: 1.8rem;
		padding: 30px 60px;
	}
	.intro-button {
		font-size: 1.8rem;
	}
	.code-display {
		font-size: 1.8rem;
		width: 96vw;
	}
	.title-definition-text {
		width: 72vw;
		font-size: 2rem;
	}
	.share-button {
		right: 4vw;
		bottom: 10vw;
		height: 6vw;
		width: 12vw;
		font-size: 1.2rem;
		letter-spacing: 0.05;
	}
}

@media (min-width: 1200px) {
	.title-header {
		font-size: 3rem;
	}
	.submit-button {
		bottom: 10vh;
	}
	.intro-button {
		width: 32vw;
		height: 12vh;
	}
}

@media screen and (min-width: 1001px) and (max-height: 600px) {
	.intro-button-form {
		bottom: 12vh;
	}

	.submit-button {
		bottom: 7vh;
	}

	.code-display {
		font-size: 1rem;
	}
	.share-button {
		right: 4vw;
		bottom: 6vw;
		height: 5vw;
		width: 10vw;
	}
}

@media screen and (min-width: 1001px) {
	.share-button {
		right: 4vw;
		bottom: 6vw;
		height: 5vw;
		width: 10vw;
	}
}
